@font-face {
  font-display: block;
  font-family: "Poison";
  src: local("Poison"),
      url(poison/Poison-Regular.otf) format("opentype") tech(color-COLRv1), url(poison/Poison-Regular.ttf) format("opentype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poison';
  background-color: #0f0f0f;
}

.hover-grid:hover {
  opacity: 0.3;
}

.hover-grid-link-child1:hover {
  background-color: orangered;
  border: 3px solid orangered;
}

.hover-grid-link-child2:hover {
  background-color: orangered;
  border: 3px solid orangered;
}

.hover-link:hover {
  color: orangered;
  display: flex;
}

/* .hover-grid-link:hover{opacity: 1;} */
.click-color {
  color: orangered;
}

/* font-family:wfont_b0dbc0_13ffb9ae83134f7a80c83fbb7ca34e8f,wf_13ffb9ae83134f7a80c83fbb7,orig_poison_regular; */

.hove-pp:hover {
  color: rgb(238, 136, 42);
}

.hove-range:hover {
  background-color: rgb(238, 136, 42);
}

.play-style1 {
  display: block;
  font-size: 35px;
  cursor: pointer
}

.pause-style1 {
  display: none;
  font-size: 35px;
  cursor: pointer
}

.play-style2 {
  display: none;
  font-size: 35px;
  cursor: pointer
}

.pause-style2 {
  display: block;
  font-size: 35px;
  cursor: pointer
}

.play-style1:hover {
  color: rgb(238, 136, 42)
}

.pause-style1:hover {
  color: rgb(238, 136, 42);
}

.play-style2:hover {
  color: rgb(238, 136, 42)
}

.pause-style2:hover {
  color: rgb(238, 136, 42)
}

/* .progress[data-bg="a"]{
    color:white;
    background-color: white;
}
.progress[data-bg="a"]:hover{
    background-color: rgb(238, 136, 42);
} */

/* progress[value] {
    --color: blue; 
    --background: lightgrey; 
   the progress color 
   the background color  
    appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 200px;
    margin: 0 10px;
    border-radius: 10em;
    background: var(--background);
  }
  progress[value]::-webkit-progress-bar {
    border-radius: 10em;
    background: var(--background);
  }
  progress[value]::-webkit-progress-value {
    border-radius: 10em;
    background: var(--color);
  }
  progress[value]::-moz-progress-bar {
    border-radius: 10em;
    background: var(--color);
  } */

input[type=range] {
  appearance: none;
  /* Hides the slider so that custom slider can be made */
  /* width: 100%; */
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
  flex-grow: "1";
}

input[type=range]::-webkit-slider-thumb {
  appearance: none;
}

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}





/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  appearance: none;
  height: 4px;
  width: 1.5em;
  border-radius: 23px;
  background: rgb(238, 136, 42);
  cursor: pointer;
  margin-top: 0px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; Add cool effects to your sliders! */
}

input[type=range]::-webkit-slider-thumb:hover {
  appearance: none;
  height: 18px;
  width: 1.5em;
  border-radius: 23px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}




input[type=range]::-webkit-slider-runnable-track {
  /* width: 100%; */
  height: 4px;
  cursor: pointer;
  background: #c6c6c6;
  border-radius: 15px;
}



/* input[type=range]:focus::-webkit-slider-runnable-track:hover{
  background: rgb(238, 136, 42);
} */

/* input[type=range]:focus{
  background: rgb(238, 136, 42);
}

input[type=range]:focus:hover{
  background: rgb(238, 136, 42);
} */

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}

input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.person-slider {
  background-color: rgb(238, 136, 42);
}

.fl-inp {
  flex-grow: "1"
}

.f1{
  display: block;
  width: 0%;
  height: 2px;
  background-color: transparent;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 600;
  animation: progress 2s ease-in-out 1 normal
}

@keyframes progress {
  0%{
      width: 0%;
      background-color: #fff
  
  }
  5%{
      width: 5%;
      background-color: #fff
  
  }
  10%{
      width: 10%;
      background-color: #fff
  
  }
  15%{
      width: 15%;
      background-color: #fff
  
  }
  20%{
      width: 20%;
      background-color: #fff
  
  }
  25%{
      width: 25%;
      background-color: #fff
  
  }
  30%{
      width: 30%;
      background-color: #fff
  
  }
  35%{
      width: 35%;
      background-color: #fff
  
  }
  40%{
      width: 40%;
      background-color: #fff
  
  }
  45%{
      width: 45%;
      background-color: #fff
  
  }
  50%{
      width: 50%;
      background-color: #fff
  
  }
  55%{
      width: 55%;
      background-color: #fff
  
  }
  60%{
      width: 60%;
      background-color: #fff
  
  }
  65%{
      width: 65%;
      background-color: #fff
  
  }
  70%{
      width: 70%;
      background-color: #fff
  
  }
  75%{
      width: 75%;
      background-color: #fff
  
  }
  80%{
      width: 80%;
      background-color: #fff
  
  }
  85%{
      width: 85%;
      background-color: #fff
  
  }
  90%{
      width: 90%;
      background-color: #fff
  
  }
  95%{
      width: 95%;
      background-color: #fff
  
  }
  100%{
      width: 100%;
      background-color: #fff
  
  }

}

.f2{display: none;}

.f2-a{
  display: grid;
  width: 40px;
  /* height: 55px; */
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 85%;
  right: 2.0%;
  border-radius: 75px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 8%);
  z-Index: 600;
}

.f2-b{
  display: none;
  width: 40px;
  /* height: 55px; */
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 85%;
  right: 2.0%;
  border-radius: 75px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 8%);
  z-index: 600;
}

.f2-c{
  display: grid;
  width: 40px;
  /* height: 55px; */
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 85%;
  right: 2.0%;
  border-radius: 75px;
  text-align: center;
  background-color: #ff4e4e;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 8%);
  z-index: 600;
}


/* progress{
  appearance: none;
  color: #fff;
}

progress::-webkit-progress-bar{
  width: 100%;
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
} */


